import React, {useState} from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap'
import { Link } from 'gatsby'
import  {Button } from 'reactstrap'



import './burgerComponent.scss'
import '../GlobalStyle.scss'



const BurgerComponent = ({
    innerClassName,
    innerId,
    selectedState,
    buttonStatus,
    containerStyle,
    contactSalesLink
}) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown
            className='burgerComponentDropDownContainer'
            id={innerId}
            isOpen={dropdownOpen} 
            toggle={toggle}>
            <DropdownToggle    
                id='burgerComponentDropDown'
            >
                <span id='burgerComponentDropDownFirstSpan' />
                <span id='burgerComponentDropDownSecondSpan' />
                <span id='burgerComponentDropDownThirdSpan' />
            </DropdownToggle>
            <DropdownMenu 
                style={{...containerStyle}}
                right
            >
                <DropdownItem 
                    className='burgerComponentDropDownItem'
                    >
                    <Link 
                        to='/'
                        className='globalLinks'
                        style={selectedState === 'index' ? {color: '#2e2e2e', fontWeight: 'bold'} : {}}
                        >
                        Recruiting Assistant
                    </Link>
                </DropdownItem>
                <DropdownItem
                    className='burgerComponentDropDownItem'>
                    <Link 
                        to='/pricing'
                        className='globalLinks'
                        style={selectedState === 'pricing' ? {color: '#2e2e2e', fontWeight: 'bold'} : {}}
                        >
                        Pricing
                    </Link>
                </DropdownItem>
                <DropdownItem
                    className='burgerComponentDropDownItem'>
                    <Link 
                        to='/support'
                        className='globalLinks'
                        style={selectedState === 'support' ? {color: '#2e2e2e', fontWeight: 'bold'} : {}}
                        >
                        Support
                    </Link>
                </DropdownItem>
                <DropdownItem
                    className='burgerComponentDropDownItem'>
                    <Link 
                        to='/careers'
                        className='globalLinks'
                        style={selectedState === 'careers' ? {color: '#2e2e2e', fontWeight: 'bold'} : {}}
                        >
                        Careers
                    </Link>
                </DropdownItem>
                { buttonStatus && 
                    <React.Fragment>
                        <DropdownItem divider />
                        <DropdownItem
                            className='burgerComponentDropDownItem'>
                            <a href={contactSalesLink ? contactSalesLink : '#contactSalesPanel'}>
                                <Button
                                    id='burgerButton'
                                    color='primary'
                                >
                                    Contact Sales

                                </Button>
                            </a>
                        </DropdownItem>

                    </React.Fragment>
                }
            </DropdownMenu>
        </Dropdown>
    )
}

export default BurgerComponent
