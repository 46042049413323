import React from "react";

import { Button } from "reactstrap";

import "./WebsiteNavigationBar.scss";

import DNNae from "../Images/DNNae.svg";
// import DNNaeText from "../Images/DNNaeText.svg";
import { Link } from "gatsby";
import BurgerComponent from "../burgerComponent/BurgerComponent";

const WebsiteNavigationBar = ({
  selectedState,
  burgerContainerStyle,
  buttonStatus = true,
  contactSalesLink = "",
}) => {
  return (
    <div className="websiteNavBarContainer">
      <span>
        <Link to="/">
          <span className="websiteNavBarLogoContainer">
            <img src={DNNae} />
          </span>
        </Link>

        <span
          className="websiteNavBarLinksContainer"
          style={{
            fontFamily: "Rubik",
          }}
        >
          <Link
            to="/"
            className="globalLinks"
            style={
              selectedState === "index"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
          >
            Recruiting Assistant
          </Link>
          <Link
            to="/pricing"
            className="globalLinks"
            style={
              selectedState === "pricing"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
          >
            Pricing
          </Link>
          <Link
            to="/support"
            className="globalLinks"
            style={
              selectedState === "support"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
          >
            Support
          </Link>
          <Link
            to="/careers"
            className="globalLinks"
            style={
              selectedState === "careers"
                ? { color: "#2e2e2e", fontWeight: "bold" }
                : {}
            }
          >
            Careers
          </Link>
        </span>

        {buttonStatus && (
          <a
            href={contactSalesLink ? contactSalesLink : "#contactSalesPanel"}
            className="websiteNavBarButtonContainer"
          >
            <Button
              color="primary"
              style={{
                width: "max-content",
              }}
              onClick={() =>
                console.log(
                  "==========CONTACT SALES VALUE===============",
                  contactSalesLink
                )
              }
            >
              Contact Sales
            </Button>
          </a>
        )}

        {/* websiteNavBarDropperContainer */}

        <BurgerComponent
          containerStyle={burgerContainerStyle}
          selectedState={selectedState}
          innerId="websiteNavBarDropperContainer"
          buttonStatus={buttonStatus}
          contactSalesLink={contactSalesLink}
        />
      </span>
    </div>
  );
};

export default WebsiteNavigationBar;
